@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

html,
body {
  @apply text-gray-800;
}

/* Hides element by taking it out of the viewport this keeps it visible in screenreaders */
.visually-hidden {
  position: absolute;
  top: -9999px;
  left: -9999px;
}

@keyframes spinning-animation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
